export const FLAGS = ['sk', 'uk', 'cz', 'de', 'hu', 'ua'];
export const FLAGS_FULL_NAMES = {
    sk: "Slovak Language",
    uk: "English Language",
    cz: 'Czech Language',
    de: 'German Language',
    hu: 'Hungarian Language',
    ua: 'Ukraine Language'
}
export const TITLE = "Flare tech"
export const ROLES = {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    WORKER: 'worker',
}
export const ORGANIZATION = "organization";
export const DEPARTMENT = "department";
export const ADMIN = "admin";
export const WORKER = "worker";
export const SUPER_ADMIN_SIGN = "Super admin";
export const ADMIN_SIGN = "Admin";
export const MANAGER_SIGN = "Manager";
export const WORKER_SIGN = "Worker";
export const NEW_STAFF = "newStaff";
export const NUMBER_DS = "number ds";
export const YES = "yes";
export const NO = "no";
export const DAY = "day";
export const NIGHT = "night";
export const DAY_NIGHT = [DAY, NIGHT];
export const WEEK = "week";
export const WEEKEND = "weekend";
export const WEEK_WEEKEND = [WEEK, WEEKEND];
export const YES_NO = [YES, NO];
export const WORK_75 = "8";
export const WORK_115 = "12";
export const WORK_TYPE_LOADS = [WORK_75, WORK_115];
export const WORK_TYPE_LOADS_MANAGER = [WORK_75];
export const HOLIDAYS = "holidays";
export const SICK = "sick";
export const FREE_DAYS = "freeDays";
export const PARAGRAPH = "paragraph";
export const LEARNING = "learning";
export const PN = "pn";
export const SUBSTITUTION = "substitution";
export const PICKED_DAYS = "pickedDays";
export const REQUIREMENTS = [HOLIDAYS, SICK, FREE_DAYS, PARAGRAPH, LEARNING, PN, PICKED_DAYS];
export const CD = "cd";
export const NO_ = "no";
export const CD_SING = "dayShift"
export const NO_SING = "nightShift";
export const EMAIL = "planly.service@gmail.com"


export enum JOB_STATUS {
    DONE = "Done",
    FAILED = "Failed",
}

